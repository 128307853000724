import "./src/styles/global.css"

export const onInitialClientRender = () => {
  function loadGtag() {
    // Inject the gtag script dynamically
    const script = document.createElement("script")
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-2TX9YBXS0R`
    script.async = true

    // Define the gtag function after script loads
    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag() {
        window.dataLayer.push(arguments)
      }
      gtag("js", new Date())
      gtag("config", "G-2TX9YBXS0R")
    }

    document.head.appendChild(script)

    // Remove event listener after loading
    window.removeEventListener("scroll", loadGtag)
    window.removeEventListener("click", loadGtag)
  }

  // Listen for user interaction before loading gtag
  window.addEventListener("scroll", loadGtag, { once: true })
  window.addEventListener("click", loadGtag, { once: true })
}
